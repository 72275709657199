import React from 'react';
import { SnippetsOutlined, HddOutlined, UsergroupAddOutlined, FileProtectOutlined } from '@ant-design/icons';
//<SnippetsOutlined />项目管理
const routes: IRoute[] = [
  {
    path: '/',
    component: () => import('layouts/BlankLayout'),
    routes: [
      {
        path: '/admin',
        component: () => import('layouts/UserLayout'),
        routes: [
          {
            path: '/admin/login',
            component: () => import('pages/admin/login')
          }
        ]
      },
      {
        path: '/',
        component: () => import('layouts/BasicLayout'),
        routes: [
          {
            path: '/project',
            name: '项目管理',
            icon: <SnippetsOutlined />,
            component: () => import('layouts/BlankLayout'),
            routes: [
              {
                path: '/project/management',
                component: () => import('pages/project/ProjectManagment'),
                name: '项目列表'
              },
              {
                path: '/project/detail/:id/:companyCode',
                component: () => import('pages/project/ProjectDetail'),
                name: '项目详情',
                hideInSideBar: true
              },
              {
                path: '/project/label/:projectId/:taskId/:companyCode',
                component: () => import('pages/project/Label'),
                name: '标注',
                hideInSideBar: true,
                sideBarCollapse: true
              }
            ]
          },
          {
            path: '/model',
            name: '模型管理',
            icon: <HddOutlined />,
            component: () => import('layouts/BlankLayout'),
            routes: [
              {
                path: '/model/management',
                component: () => import('pages/model/ModelManagment'),
                name: '模型列表'
              },
              {
                path: '/model/detail/:id',
                component: () => import('pages/model/ModelDetail'),
                name: '模型详情',
                hideInSideBar: true
              }
            ]
          },
          {
            path: '/user-management',
            name: '用户管理',
            icon: <UsergroupAddOutlined />,
            component: () => import('pages/user/UserManagment')
          },
          {
            path: '/approval-management',
            name: '审批管理',
            icon: <FileProtectOutlined />,
            component: () => import('pages/approval/ApprovalManagement')
          },
          {
            component: () => import('pages/exception/notFound')
          }
        ]
      }
    ]
  }
];
const _memberRoutes: IRoute[] = [
  {
    path: '/',
    component: () => import('layouts/BlankLayout'),
    routes: [
      {
        path: '/admin',
        component: () => import('layouts/UserLayout'),
        routes: [
          {
            path: '/admin/login',
            component: () => import('pages/admin/login')
          }
        ]
      },
      {
        path: '/',
        component: () => import('layouts/BasicLayout'),
        routes: [
          {
            path: '/project',
            name: '项目管理',
            icon: <SnippetsOutlined />,
            component: () => import('layouts/BlankLayout'),
            routes: [
              {
                path: '/project/management',
                component: () => import('pages/project/ProjectManagment'),
                name: '项目列表'
              },
              {
                path: '/project/detail/:id/:companyCode',
                component: () => import('pages/project/ProjectDetail'),
                name: '项目详情',
                hideInSideBar: true
              },
              {
                path: '/project/label/:projectId/:taskId/:companyCode',
                component: () => import('pages/project/Label'),
                name: '标注',
                hideInSideBar: true,
                sideBarCollapse: true
              }
            ]
          },
          {
            component: () => import('pages/exception/notFound')
          }
        ]
      }
    ]
  }
];

const _projectRoutes: IRoute[] = [
  {
    path: '/',
    component: () => import('layouts/BlankLayout'),
    routes: [
      {
        path: '/',
        component: () => import('layouts/BasicLayout'),
        routes: [
          {
            path: '/project',
            name: '项目管理',
            icon: <SnippetsOutlined />,
            component: () => import('layouts/BlankLayout'),
            routes: [
              {
                path: '/project/management',
                component: () => import('pages/project/ProjectManagment'),
                name: '项目列表'
              },
              {
                path: '/project/detail/:id/:companyCode',
                component: () => import('pages/project/ProjectDetail'),
                name: '项目详情',
                hideInSideBar: true
              },
              {
                path: '/project/label/:projectId/:taskId/:companyCode',
                component: () => import('pages/project/Label'),
                name: '标注',
                hideInSideBar: true,
                sideBarCollapse: true
              }
            ]
          },
          {
            path: '/user-management',
            name: '用户管理',
            icon: <UsergroupAddOutlined />,
            component: () => import('pages/user/UserManagment')
          },
          {
            path: '/approval-management',
            name: '审批管理',
            icon: <FileProtectOutlined />,
            component: () => import('pages/approval/ApprovalManagement')
          },
          {
            component: () => import('pages/exception/notFound')
          }
        ]
      }
    ]
  }
];

function addLazyComponent(routes: IRoute[]) {
  routes.forEach(route => {
    route.component = React.lazy(route.component);
    if (route.routes) {
      addLazyComponent(route.routes);
    }
  });
}
addLazyComponent(routes);
addLazyComponent(_memberRoutes);
addLazyComponent(_projectRoutes);

export const memberRoutes = _memberRoutes;
export const projectRoutes = _projectRoutes;
export default routes;
