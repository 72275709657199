import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Loading from 'components/Loading';
interface IRoutesProps {
  routes: IRoute[];
}
const Routes: React.FC<IRoutesProps> = props => {
  if (!Array.isArray(props.routes)) return null;
  return (
    <Switch>
      {props.routes.map(({ component: Component, ...route }, index) => {
        return (
          <Route
            path={route.path}
            key={index}
            {...route}
            render={routeProps => {
              if (route.redirect) {
                return <Redirect to={route.redirect} />;
              }
              return (
                <Suspense fallback={<Loading />}>
                  <Component {...route}>
                    <Routes routes={route.routes as []} />
                  </Component>
                </Suspense>
              );
            }}
          />
        );
      })}
    </Switch>
  );
};
Routes.defaultProps = {
  routes: []
};
export default React.memo(Routes);
