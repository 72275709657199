import { createSlice } from '@reduxjs/toolkit';
import { RootState, AppDispatch } from '../index';
import ProjectService, {
  addProjectParams,
  updateProjectParams,
  DownloadParams,
  UploadParams,
  queryTaskParams,
  UserParams,
  approveParams,
  approveRecordProps,
  updateTaskProps,
  corpusListParams,
  CreateTaskParams,
  GetApprovalsParams,
  UpdateApprovalParams
} from '../../services/project';
import ModelService from '../../services/model';
import UserService from '../../services/user';
import { message } from 'antd';
import store from '../index';
import { get as _get } from 'lodash';
import { compose } from 'redux';

export enum Status {
  idle = 'idle',
  loading = 'loading',
  succeeded = 'succeeded',
  failed = 'failed'
}

export const slice = createSlice({
  name: 'project',
  initialState: {
    status: Status.idle,
    projectListTrigger: 1,
    models: [],
    modelsLoading: false,
    users: [],
    approvalNum: 0,
    approvalListTrigger: 1
  },
  reducers: {
    setProjectListTrigger: function (state) {
      state.projectListTrigger += 1;
    },
    setApprovalListTrigger: function (state) {
      state.approvalListTrigger += 1;
    },
    setModels: function (state, { payload }) {
      state.models = payload;
    },
    setUsers: function (state, { payload }) {
      state.users = payload;
    },
    setApprovalNum: function (state, { payload }) {
      state.approvalNum = payload;
    },
    setModelsLoading: function (state, { payload }) {
      state.modelsLoading = payload;
    }
  }
});

export const { setProjectListTrigger, setModels, setUsers, setApprovalNum, setApprovalListTrigger, setModelsLoading } = slice.actions;
export default slice.reducer;
export const selectModels = (state: RootState) => state.project.models;
export const selectModelsLoading = (state: RootState) => state.project.modelsLoading;
export const selectUsers = (state: RootState) => state.project.users;
export const selectApprovalNum = (state: RootState) => state.project.approvalNum;
export const selectProjectListTrigger = (state: RootState) => state.project.projectListTrigger;
export const selectApprovalListTrigger = (state: RootState) => state.project.approvalListTrigger;

export async function addProject(params: addProjectParams) {
  try {
    const res = await ProjectService.addProject(params);
    if (res.success) {
      message.success(res.msg || '创建标注项目成功');
      store.dispatch(setProjectListTrigger());
    } else {
      throw res.msg;
    }
  } catch (e) {
    console.log(e);
    message.error(e || '创建标注项目失败');
    return Promise.reject(e);
  }
}

export async function updateProject(params: updateProjectParams) {
  try {
    const res = await ProjectService.updateProject(params);
    if (res.success) {
      message.success(res.msg || '更新标注项目成功');
      store.dispatch(setProjectListTrigger());
      return true;
    } else {
      throw res.msg;
    }
  } catch (e) {
    console.log(e);
    message.error(e || '更新标注项目失败');
    return Promise.reject(e);
  }
}

export async function getProjectDetail(id: string) {
  try {
    const res = await ProjectService.getProjectDetail(id);
    if (res.success) {
      return res.data;
    } else {
      throw res.msg;
    }
  } catch (e) {
    console.log(e);
    message.error(e || '获取标注项目详情失败');
    return Promise.reject(e);
  }
}

var funDownload = function (content: any, filename: string) {
  var eleLink = document.createElement('a');
  eleLink.target = '_blank';
  eleLink.download = filename;
  eleLink.style.display = 'none';
  var blob = new Blob([content]);
  const href = URL.createObjectURL(blob);
  eleLink.href = href;
  document.body.appendChild(eleLink);
  eleLink.click();
  document.body.removeChild(eleLink);
};

export async function download(params: DownloadParams) {
  try {
    const res = await ProjectService.download(params);
    const filename = compose((res: string) => res ? decodeURIComponent(res) : 'file.xlsx')((_get(res, 'headers.content-disposition', '').match(/(?<=filename\=).+/) || [])[0]);
    const file = res.data;
    funDownload(file, filename);
  } catch (e) {
    console.log(e);
    message.error(e || '下载失败');
    return Promise.reject(e);
  }
}

export async function upload(params: UploadParams) {
  try {
    const res = await ProjectService.upload(params);
    if (res.success) {
      message.success(res.msg || '上传成功。');
      return res.data;
    } else {
      throw res.msg;
    }
  } catch (e) {
    console.log(e);
    message.error(e || '上传失败。');
    return Promise.reject(e);
  }
}

export async function getModels() {
  try {
    store.dispatch(setModelsLoading(true));
    const res = await ModelService.getModels({ isPage: false, status: 'ACTIVE' } as any);
    if (res.success) {
      store.dispatch(setModels(res.data));
      return true;
    } else {
      throw res.msg;
    }
  } catch (e) {
    console.log(e);
    message.error(e || '获取模型列表失败');
    return Promise.reject(e);
  } finally {
    store.dispatch(setModelsLoading(false));
  }
}

export async function getProjectTask(params: queryTaskParams) {
  try {
    return await ProjectService.getProjectTask(params);
  } catch (e) {}
}

export async function getUserList(params: UserParams) {
  try {
    return await ProjectService.getUserList(params);
  } catch (e) {
    console.log(e);
  }
}

export async function projectApprove(params: approveParams) {
  try {
    const res = await ProjectService.addApprove(params);
    if (res.success) {
      message.success('提交审批成功！');
      return res;
    } else {
      throw res;
    }
  } catch (e) {
    message.error('提交审批失败！');
    return Promise.reject(e);
  }
}

export async function getUsers() {
  try {
    const res = await UserService.getUserList({ isPage: false } as any);
    if (res.success) {
      store.dispatch(setUsers(res.data));
      return res.data;
    } else {
      throw res.msg;
    }
  } catch (e) {
    console.log(e);
    message.error(e || '获取用户列表失败');
    return Promise.reject(e);
  }
}

export async function CreateTask(params: CreateTaskParams) {
  try {
    const res = await ProjectService.createTask(params);
    if (res.success) {
      store.dispatch(setProjectListTrigger());
      message.success(res.msg || '创建任务成功。');
      return res.data;
    } else {
      throw res.msg;
    }
  } catch (e) {
    console.log(e);
    message.error(e || '创建任务失败。');
    return Promise.reject(e);
  }
}

export async function updateTaskStatus(params: updateTaskProps) {
  try {
    const res = await ProjectService.updateTask(params);
    if (res.success) {
      message.success(res.msg || '任务终止成功');
      return res
    } else {
      throw res.msg;
    }
  } catch (e) {
    message.error(e || '任务终止失败');
    return Promise.reject(e)
  }
}

export async function getApprovals(params: GetApprovalsParams) {
  try {
    const res = await ProjectService.getApprovals(params);
    if (res.success) {
      return res;
    } else {
      throw res.msg;
    }
  } catch (e) {
    console.log(e);
    message.error(e || '获取审批列表失败');
    return Promise.reject(e);
  }
}

export async function approveTask(params: approveRecordProps) {
  return await ProjectService.approveTask(params);
}

export async function corpusList(params: corpusListParams) {
  try {
    const res = await ProjectService.getCorpusList(params);
    if (res.success) {
      return res;
    } else {
      throw res;
    }
  } catch (e) {
    message.error('获取语料列表失败');
    return Promise.reject(e);
  }
}

let getApprovalsNumLoading = false;

export async function getApprovalsNum() {
  try {
    if (getApprovalsNumLoading) return;
    getApprovalsNumLoading = true;
    const res = await ProjectService.getApprovalsNum();
    store.dispatch(setApprovalNum(_get(res, 'data', 0)));
  } catch (e) {
    console.log(e);
    message.error(e || '获取审批列表失败');
    return Promise.reject(e);
  } finally {
    getApprovalsNumLoading = false;
  }
}

export async function updateApproval(params: UpdateApprovalParams, cb?: () => void) {
  try {
    if (!params.id) return;
    const res = await ProjectService.updateApproval(params);
    if (res.success) {
      store.dispatch(setApprovalListTrigger());
      message.success(res.msg || '更新审批成功。');
      getApprovalsNum();
      cb && cb();
      return res.data;
    } else {
      throw res.msg;
    }
  } catch (e) {
    console.log(e);
    message.error(e || '更新审批失败。');
    return Promise.reject(e);
  }
}
