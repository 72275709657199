import React, { FC, useMemo } from 'react';
import { Router } from 'react-router-dom';
// import { ConfigProvider } from 'antd';
// import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import Routes from 'components/Routes';
import styles from './App.module.scss';
import history from 'libs/history';
import routes, { memberRoutes, projectRoutes } from './config/routes';
import { selectUserInfo } from './store/reducer/user';
import { useSelector } from 'react-redux';
import { get as _get } from 'lodash';

console.log('version: ', 20201130);

export enum UserIdentity {
  SUPER_ADMIN = '0', //超级管理员
  PROJECT_ADMIN = '1', //客户管理员
  MEMBER = '2' //标注员
}

const App = () => {
  const memberIdentity = useSelector((state: any) => _get(state, 'user.info.memberIdentity'));

  const mapRoutes = useMemo(() => {
    if (memberIdentity === UserIdentity.MEMBER) {
      return memberRoutes;
    }
    if (memberIdentity === UserIdentity.PROJECT_ADMIN) {
      return projectRoutes;
    }
    return routes;
  }, [memberIdentity]);

  return (
    <div className={styles.root}>
      <Router history={history}>
        {/* <Routes routes={routes} /> */}
        <Routes routes={mapRoutes} />
      </Router>
    </div>
  );
};
export default App;
