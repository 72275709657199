import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';

export const slice = createSlice({
  name: 'layout',
  initialState: {
    headerVisible: true
  },
  reducers: {
    setHeaderVisible: (state, { payload }) => {
      state.headerVisible = payload;
    }
  }
});

export default slice.reducer;

export const { setHeaderVisible } = slice.actions;

export const selectHeaderVisible = (state: RootState) =>
  state.layout.headerVisible;
