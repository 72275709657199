import { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from './axios';
// HTTP 只处理 status是200的响应，res是response data
export interface IResponse<T> {
  success: boolean;
  code?: string;
  message?: string;
  msg?: string;
  data: T;
}
class HTTP {
  config: AxiosRequestConfig = {
    baseURL: axios.defaults.baseURL
  };
  private handlerError() {
    try {
    } catch (e) {
      console.log(e);
    }
  }
  private getConfig(config?: AxiosRequestConfig): AxiosRequestConfig {
    return Object.assign({}, this.config, config || {});
  }
  protected async get(url: string, config?: AxiosRequestConfig) {
    try {
      const res: IResponse<any> = await axios.get(url, this.getConfig(config));
      return res;
    } catch (e) {
      console.log(e);
    }
    return {
      success: false,
      data: null
    };
  }
  protected async post(url: string, data?: any, config?: AxiosRequestConfig) {
    try {
      const res: IResponse<any> = await axios.post(
        url,
        data,
        this.getConfig(config)
      );
      return res;
    } catch (e) {
      console.log(e);
    }
    return {
      success: false,
      data: null
    };
  }
  protected async delete(url: string, config?: AxiosRequestConfig) {
    try {
      const res: IResponse<any> = await axios.delete(
        url,
        this.getConfig(config)
      );
      return res;
    } catch (e) {
      console.log(e);
    }
    return {
      success: false,
      data: null
    };
  }
  protected async put(url: string, data?: any, config?: AxiosRequestConfig) {
    try {
      const res: IResponse<any> = await axios.put(
        url,
        data,
        this.getConfig(config)
      );
      return res;
    } catch (e) {
      console.log(e);
    }
    return {
      success: false,
      data: null
    };
  }
  protected async patch(url: string, data?: any, config?: AxiosRequestConfig) {
    const res = await axios.patch(url, data, config);
    return res;
  }
  protected async request(config: AxiosRequestConfig) {
    const res = await axios.request(config);
    return res;
  }
  protected async head(url: string, config?: AxiosRequestConfig) {
    const res = await axios.head(url, config);
    return res;
  }
  protected async options(url: string, config?: AxiosRequestConfig) {
    const res = await axios.options(url, config);
    return res;
  }
}
export default HTTP;
