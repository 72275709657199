class User {
  private tokenKey = 'HRTPS_servier_userToken';
  private store: any = window.localStorage;
  getToken() {
    return this.store && this.store.getItem(this.tokenKey);
  }
  setToken(token: string) {
    this.store && this.store.setItem(this.tokenKey, token);
  }
  removeToken() {
    this.store && this.store.removeItem(this.tokenKey);
  }
}
export default new User();
