import axios from 'axios';
import config from 'config';
import mq, { EnumMessageStatus } from 'libs/messageQueue';
import user from '../../libs/user';
import history from '../history';
import { get as _get } from 'lodash';

enum StatusCode {
  success = 200,
  unauthorized = 401
}
const axiosInstance = axios.create({
  baseURL: config.API_URL,
  timeout: 10000
});
axiosInstance.interceptors.request.use(
  function (config) {
    if (config.url === '/management/project/download/') {
      config.responseType = 'blob';
    }
    const token = user.getToken();
    if (token) {
      config.headers['Authorization'] = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    if (response.status === StatusCode.success) {
      const { data } = response;
      if (_get(response, 'headers.content-type', '').match('application/octet-stream')) {
        return response;
      }

      return data;
    } else {
      mq.push({
        status: EnumMessageStatus.fail,
        text: 'xxx fail'
      });
      return {
        ...response.data,
        success: false
      };
    }
  },
  function (error) {
      const url = window.location.href;
      if (_get(error, 'response.status') === StatusCode.unauthorized && !/\/admin\/login/.test(url)) {
        let from = url.substring(url.indexOf('#') + 1);
        from = from.indexOf('/admin/login') > -1 ? '/' : from;
        const nextPage = encodeURI('/admin/login?from=' + from);
        history.replace(nextPage);
      }
      return {
        success: false
      };
  }
);
export default axiosInstance;
