interface IOptions {
  maxLength: number;
}
export enum EnumMessageStatus {
  success = 0,
  fail = 1,
  info = 2
}
export interface IMessage {
  status: EnumMessageStatus;
  text: string;
}
class MessageQueue {
  private maxLength = 10;
  private queue: IMessage[] = [];
  constructor(options?: IOptions) {
    if (options) {
      this.maxLength = options.maxLength;
    }
  }
  push(message: IMessage) {
    // todo
    this.queue.push(message);
  }
}
export default new MessageQueue();
