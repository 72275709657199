import HTTP from 'libs/HTTP';

export interface GetModelsParams {
  pageIndex: number;
  pageSize: number;
  companyCode?: string;
  name?: string;
  status?: string;
  telephone?: string;
  isPage?: boolean;
}

export interface GetModelDetailParams {
  id: string;
}

export interface addModelParams {
  code?: string;
  desc?: string;
  name?: string;
}

export interface updateModelParams extends addModelParams {
  id: string;
  status?: string;
}

interface ISubDimensionItem {
  begin?: number;
  color?: string;
  end?: number;
  subDimensionCode: string;
  subDimensionName: string;
}

export interface addDimensionParams {
  dimensionDesc?: string;
  dimensionName: string;
  dimensionType: string;
  isMulti?: boolean;
  isNecessary: boolean;
  modelId: string;
  subDimensions: ISubDimensionItem[];
}

export interface GetDimensionDetailParams {
  id: string;
}

export interface UpdateDimensionParams extends addDimensionParams {
  id: string;
}

export interface GetDimensionsParams {
  id: string;
}

class Model extends HTTP {
  async getModels(params: GetModelsParams) {
    return await this.post('/management/model/query-model/', {
      ...params
    });
  }

  async getModelDetail(params: GetModelDetailParams) {
    return await this.get(`/management/model/model-detail?id=${params}`);
  }

  async addModel(params: addModelParams) {
    return await this.post('/management/model/add-model/', {
      ...params
    });
  }

  async updateModel(params: updateModelParams) {
    return await this.post('/management/model/update-model/', {
      ...params
    });
  }

  async addDimension(params: addDimensionParams) {
    return await this.post('/management/model/add-dimension/', {
      ...params
    });
  }

  async getDimensionDetail(params: GetDimensionDetailParams) {
    return await this.get(`/management/model/dimension-detail?id=${params.id}`);
  }

  async updateDimension(params: UpdateDimensionParams) {
    return await this.post('/management/model/update-dimension/', params);
  }

  async getDimensions(params: GetDimensionsParams) {
    return await this.get(`/management/model/query-dimension?id=${params.id}`);
  }
}
export default new Model();
