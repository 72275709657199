import HTTP from 'libs/HTTP';

export interface GetUserParams {
  pageIndex: number;
  pageSize: number;
  companyCode?: string;
  name?: string;
  status?: string;
  telephone?: string;
}

export interface CreateUserParams {
  name: string;
  password: string;
  telephone?: string;
  userName: string;
}

export interface UpdateUserParams {
  id?: any;
  name?: string;
  password?: string;
  telephone?: string;
  userName?: string;
  status?: any;
}

export interface LoginParams {
  loginPassword: string;
  telephone: string;
}

class User extends HTTP {
  async checkUser(account: string) {
    const response = await this.get(
      '/common-auth-server/api/c/auth/account/check',
      {
        params: { account }
      }
    );
    return response;
  }

  async getUserList(params: GetUserParams) {
    return await this.post('/inter/user/query-user/', {
      ...params
    });
  }

  async getUserCompanies() {
    return await this.get('/inter/user/query-company/');
  }

  async CreateUser(params: CreateUserParams) {
    return await this.post('/inter/user/add-user/', {
      ...params
    });
  }

  async UpdateUser(params: UpdateUserParams) {
    return await this.post('/inter/user/update-user/', {
      ...params
    });
  }

  async ResetPassword(params: UpdateUserParams) {
    return await this.post('/inter/user/reset-password/', {
      ...params
    });
  }

  async Login(params: LoginParams) {
    return await this.post('/inter/user/user-login/', {
      ...params
    });
  }

  async getUserInfo() {
    return await this.get('/inter/user/user-info/');
  }
}
export default new User();
