import { createSlice } from '@reduxjs/toolkit';
import { RootState, AppDispatch } from '../index';
import ModelService, {
  addModelParams,
  updateModelParams,
  GetModelDetailParams,
  GetDimensionDetailParams,
  UpdateDimensionParams,
  GetDimensionsParams
} from '../../services/model';
import { message } from 'antd';
import store from '../index';

export enum Status {
  idle = 'idle',
  loading = 'loading',
  succeeded = 'succeeded',
  failed = 'failed'
}

export const slice = createSlice({
  name: 'model',
  initialState: {
    status: Status.idle,
    listTrigger: 1
  },
  reducers: {
    setListTrigger: function (state) {
      state.listTrigger += 1;
    }
  }
});

export const { setListTrigger } = slice.actions;
export default slice.reducer;

export const selectListTrigger = (state: RootState) => state.model.listTrigger;

export async function addModel(params: addModelParams) {
  try {
    const res = await ModelService.addModel(params);
    if (res.success) {
      message.success(res.msg || '创建模型成功');
      store.dispatch(setListTrigger());
    } else {
      throw res.msg;
    }
  } catch (e) {
    console.log(e);
    message.error(e || '创建模型失败');
    return Promise.reject(e);
  }
}

export async function updateModel(params: updateModelParams) {
  try {
    const res = await ModelService.updateModel(params);
    if (res.success) {
      message.success(res.msg || '更新模型成功');
      store.dispatch(setListTrigger());
      return true;
    } else {
      throw res.msg;
    }
  } catch (e) {
    console.log(e);
    message.error(e || '更新模型失败');
    return Promise.reject(e);
  }
}

export async function getModelDetail(id: GetModelDetailParams) {
  try {
    const res = await ModelService.getModelDetail(id);
    if (res.success) {
      return res.data;
    } else {
      throw res.msg;
    }
  } catch (e) {
    console.log(e);
    message.error(e || '获取模型详情失败');
    return Promise.reject(e);
  }
}

export async function getDimensionDetail(params: GetDimensionDetailParams) {
  try {
    const { success, msg, data } = await ModelService.getDimensionDetail(
      params
    );
    if (success) {
      return data;
    } else {
      throw msg;
    }
  } catch (e) {
    console.log(e);
    message.error(e || '获取维度详情失败');
    return Promise.reject(e);
  }
}

export async function updateDimension(params: UpdateDimensionParams) {
  try {
    const { success, data, msg } = await ModelService.updateDimension(params);
    if (success) {
      message.success(msg || '更新维度成功');
      return data;
    } else {
      throw msg;
    }
  } catch (e) {
    console.log(e);
    message.error(e || '更新维度失败');
    return Promise.reject(e);
  }
}
export async function getDimensions(params: GetDimensionsParams) {
  try {
    const { success, data, msg } = await ModelService.getDimensions(params);
    if (success) {
      return data;
    } else {
      throw msg;
    }
  } catch (e) {
    console.log(e);
    message.error(e || '获取维度列表失败');
    return Promise.reject(e);
  }
}
