import HTTP from 'libs/HTTP';
import { TaskStatus } from '../pages/approval/common';

export interface GetProjectsParams {
  pageIndex: number;
  pageSize: number;
  companyCode?: string;
  name?: string;
  status?: string;
  telephone?: string;
}
export interface addProjectParams {
  companyCode?: string;
  models?: string[];
  name?: string;
}

export interface updateProjectParams extends addProjectParams {
  id: string;
  status?: string;
}

export interface DownloadParams {
  projectId: string;
  url: string;
}

export interface UploadParams {
  file: FormData;
  projectId: string;
  config?: any;
}

export interface queryTaskParams {
  begin: string;
  end: string;
  name: string;
  pageIndex?: number;
  pageSize?: number;
  projectId: string;
  status: string;
}

export interface UserParams {
  isPage: boolean;
  status: string;
}

export interface approveParams {
  firstApprovers: { userId: string; userName: string }[];
  secondApprovers: { userId: string; userName: string }[];
  projectId: string;
  taskId?: string;
}

export interface UserItemProps {
  companyCode?: string;
  num: number;
  userId: string;
  userName: string;
}
export interface CreateTaskParams {
  beginId?: number;
  desc?: string;
  dueDate: number;
  name: string;
  perNum?: number;
  prior: string;
  projectId: string;
  users: UserItemProps[];
}

export interface approveRecordProps {
  doneNum: number;
  dueDate: number;
  projectId: string;
  taskId: string;
  taskName: string;
  totalNum: number;
}

export interface updateTaskProps {
  id: string;
  status: string;
}

export interface corpusListParams {
  dataStatus?: string,
  deliverStatus?: string,
  name?: string,
  page?: boolean,
  pageIndex?: number,
  pageSize?: number,
  vaildStatus?: string,
  projectId: string
}

export interface GetApprovalsParams {
  pageIndex: number;
  pageSize: number;
  companyCode?: string;
  name?: string;
  status?: string;
  telephone?: string;
}

export interface UpdateApprovalParams {
  id: string;
  status: keyof typeof TaskStatus;
  taskId?: string;
  projectId: string
}

class Project extends HTTP {
  async getProjects(params: GetProjectsParams) {
    return await this.post('/management/project/query-project/', {
      ...params
    });
  }

  async getProjectTask(params: queryTaskParams) {
    return await this.post('management/project/query-task', {
      ...params
    });
  }

  async getProjectDetail(id: string) {
    return await this.get(`/management/project/project-detail?id=${id}`);
  }

  async addProject(params: addProjectParams) {
    return await this.post('/management/project/add-project/', {
      ...params
    });
  }

  async updateProject(params: updateProjectParams) {
    return await this.post('/management/project/update-project/', {
      ...params
    });
  }

  async download(params: DownloadParams) {
    return await this.post('/management/project/download/', params);
  }

  async upload(params: UploadParams) {
    return await this.post(`/management/project/upload?projectId=${params.projectId}`, params.file, { ...params.config });
  }

  async getUserList(params: UserParams) {
    return await this.post('inter/user/query-user', { ...params });
  }

  async addApprove(params: approveParams) {
    return await this.post('/management/project/add-approver', { ...params });
  }

  async createTask(params: CreateTaskParams) {
    return await this.post('/management/project/add-task/', params);
  }

  async updateTask(params: updateTaskProps) {
    return await this.post('/management/project/update-task', params);
  }

  async approveTask(params: approveRecordProps) {
    return await this.post('/management/project/add-approver-record', params);
  }

  async getCorpusList(params: corpusListParams) {
    return await this.post('tag/data/query-data', params);
  }
  async getApprovals(params: GetApprovalsParams) {
    return await this.post('/management/project/query-approve/', params);
  }

  async getApprovalsNum() {
    return await this.post('/management/project/query-approver-num/');
  }

  async updateApproval(params: UpdateApprovalParams) {
    return await this.post('/management/project/update-approve/', params);
  }

  async queryLabelCorpus(params: any) {
    return await this.post('/tag/data/query-data/', params);
  }
  async queryLabelDetail(id: string, projectId: string) {
    return await this.get(`/tag/data/data-detail?id=${id}&projectId=${projectId}`);
  }

  async postScribbleData(params: any) {
    return await this.post('/tag/data/data-tag', params)
  }
}
export default new Project();
