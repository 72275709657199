import { combineReducers } from 'redux';
import user from './user';
import model from './model';
import project from './project';
import layout from './layout';
export const rootReducer = combineReducers({
  user,
  model,
  project,
  layout
});
