import { createSlice } from '@reduxjs/toolkit';
import { RootState, AppDispatch } from '../index';
import UserService, { LoginParams, UpdateUserParams } from '../../services/user';
import { message } from 'antd';
import store from '../index';
import UserUtils from '../../libs/user';
import history from '../../libs/history';

export enum Member {
  SUPER_ADMIN = 'SUPER_ADMIN',
  PROJECT_ADMIN = 'PROJECT_ADMIN',
  MEMBER = 'MEMBER'
}
interface Role {
  companyCode: string;
  role: keyof typeof Member;
}

export type TRoles = Role[];

export enum Status {
  idle = 'idle',
  loading = 'loading',
  succeeded = 'succeeded',
  failed = 'failed'
}

export enum UserIdentity {
  SUPER_ADMIN = '0',
  PROJECT_ADMIN = '1',
  MEMBER = '2'
}

interface State {
  status: keyof typeof Status;
  companies: any[];
  listUpdateTrigger: number;
  info: {
    companyCode: string;
    id: string;
    name: string;
    telephone: string;
    userName: string;
    roles: TRoles;
    memberIdentity: string;
  };
  users: [];
}

const initialState: State = {
  status: Status.idle,
  companies: [],
  listUpdateTrigger: 1,
  info: {
    companyCode: '',
    id: '',
    name: '',
    telephone: '',
    userName: '',
    roles: [],
    memberIdentity: ''
  },
  users: []
};

export const slice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setStatus: (state, { payload }) => {
      state.status = payload;
    },
    setCompanies: (state, { payload = [] }) => {
      state.companies = payload;
    },
    setListUpdateTrigger: state => {
      state.listUpdateTrigger += 1;
    },
    setUserInfo: (state, { payload }) => {
      state.info = payload;
    },
    setUsers: (state, { payload }) => {
      state.users = payload;
    }
  }
});

export const getCompanies = () => async (dispatch: AppDispatch, getState: any) => {
  try {
    const state = getState();
    if ((state as RootState).user.status === Status.loading) {
      return;
    }
    dispatch(setStatus(Status.loading));
    const res = (await UserService.getUserCompanies()) || {};
    if (res.success) {
      dispatch(setStatus(Status.succeeded));
      dispatch(setCompanies(res.data || []));
    } else {
      throw (res as any).msg;
    }
  } catch (e) {
    message.error(e || '获取用户公司信息失败');
    dispatch(setStatus(Status.failed));
  }
};

export const { setCompanies, setStatus, setListUpdateTrigger, setUserInfo, setUsers } = slice.actions;
export const selectUserCompanies = (state: RootState) => state.user.companies;
export const selectUserLoading = (state: RootState) => state.user.status === Status.loading;
export const selectCompaniesEmpty = (state: RootState) => state.user.companies.length === 0;
export const selectListUpdateTrigger = (state: RootState) => state.user.listUpdateTrigger;
export const selectUserInfo = (state: RootState) => state.user.info;
export default slice.reducer;
export const selectUsers = (state: RootState) => state.user.users;
export const selectUserRoles = (state: RootState) => state.user.info.roles;

export async function UpdateUser(params: UpdateUserParams) {
  const dispatch = store.dispatch;
  try {
    const res = (await UserService.UpdateUser(params)) || {};
    if (res.success) {
      message.success(res.msg || '更新用户成功');
      dispatch(setListUpdateTrigger());
      return res.data || [];
    } else {
      throw res.msg || '更新用户失败';
    }
  } catch (e) {
    message.error(e || '更新用户失败');
    dispatch(setListUpdateTrigger());
    return Promise.reject(e);
  }
}

export async function getUserInfo() {
  try {
    const dispatch = store.dispatch;
    const res = (await UserService.getUserInfo()) || {};
    if (res.success) {
      dispatch(setUserInfo(res.data));
      return true;
    } else {
      throw res.msg;
    }
  } catch (e) {
    message.error(e || '获取用户详情失败');
    return Promise.reject(e);
  }
}

export async function login(params: LoginParams) {
  try {
    const res = (await UserService.Login(params)) || {};
    if (res.success) {
      UserUtils.setToken(res.data);
      message.success(res.msg || '登录成功');
      return true;
    } else {
      throw res.msg;
    }
  } catch (e) {
    message.error(e || '登录失败');
    return Promise.reject(e);
  }
}

export async function logout() {
  UserUtils.removeToken();
  window.location.href = `${window.location.origin}/#/admin/login`;
  window.location.reload();
}

export async function getUsers() {
  try {
    const res = await UserService.getUserList({ isPage: false } as any);
    if (res.success) {
      store.dispatch(setUsers(res.data));
      return res.data;
    } else {
      throw res.msg;
    }
  } catch (e) {
    console.log(e);
    message.error(e || '获取用户列表失败');
    return Promise.reject(e);
  }
}
